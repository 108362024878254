import React from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useEffect, useMemo, useState } from 'react';
import {
  Container, CssBaseline, Typography, Stack, GlobalStyles, TextField, InputAdornment, FormControl,
  FormLabel, FormHelperText, FormControlLabel, FormGroup, Checkbox, Box, Grid, IconButton,
} from '@mui/material'
import { ContentCopy, CachedRounded } from '@mui/icons-material';
import copy from 'copy-to-clipboard';
import * as generator from 'generate-password'
import { useCallback } from 'react';

const initalValue = localStorage.getItem('form') || JSON.stringify({
  length: 12,
  numbers: true,
  symbols: '!@#$%^&',
  strict: true,
  uppercase: true,
  lowercase: true,
  excludeSimilarCharacters: false,
  exclude: '',
});
const App = () => {
  const [mode, setMode] = useState(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)
  const theme = useMemo(() => {
    return createTheme({
      palette: {
        mode: mode ? 'dark' : 'light',
      },
    })
  }, [mode]);
  useEffect(() => {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      setMode(event.matches)
    });
  }, [])

  const [form, setForm] = useState(JSON.parse(initalValue));
  const [password, setPassword] = useState(generator.generate(form));
  const [i, setI] = useState(0);

  const onChange = useCallback((e?: any) => {
    setForm((form?: any) => ({ ...form, [e.target.name]: e.target.checked }));
  }, [form]);
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    localStorage.setItem('form', JSON.stringify(form));
    const password = generator.generate(form);
    setPassword(password);
    let k = form.numbers ? 1 : 0
    k += !!form.symbols ? 1 : 0
    k += form.lowercase ? 1 : 0
    k += form.uppercase ? 1 : 0
    setDisabled(k == 1);
    setCopied(false);
  }, [form, i])
  const [copied, setCopied] = useState(false);
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles styles={{
        '@global': {
          '--transition-duration': '400ms',
          '--text': '#333',
        },
        'body': {
          transition: 'color, background-color var(--transition-duration)'
        },
        '.light': {
          '& svg': {
            transition: 'stroke 400ms ease-in-out',
            stroke: '#000',
          },
          '& .moon-icon': {
            strokeDasharray: '0px 1px',
            opacity: 0,
            transition: 'stroke-dasharray 0.5s ease-in, opacity 300ms ease-in',
          },
          '& .sun-icon': {
            strokeDasharray: '1px 1px',
            opacity: 1,
            transition: 'stroke-dasharray 0.5s ease-in, opacity 300ms ease-in',
          },
        },
        '.dark': {
          '--text': 'white',
          '& svg': {
            transition: 'stroke 400ms ease-in-out',
            stroke: '#fff',
          },
          '& .moon-icon': {
            strokeDasharray: '1px 1px;',
            opacity: 1
          },
          '& .sun-icon': {
            strokeDasharray: '0px 1px;',
            opacity: 0
          },
        }
      }} />
      <CssBaseline />
      <Container maxWidth="sm">
        <Box mt={4}>
          <Stack alignItems={'center'} spacing={4} textAlign={'center'} >
            <Typography variant="h6" component={'h1'} >Strong Password Generator</Typography>
            <div className={mode ? 'dark' : 'light'} onClick={e => setMode(!mode)}>
              <Icon />
            </div>

            <TextField
              sx={{
                'input': {
                  textAlign: 'center'
                }
              }}
              id="password"
              value={password}
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">
                  <IconButton aria-label="" onClick={() => {
                    copy(password)
                    setCopied(true);
                  }} color={copied ? 'info' : 'default'} >
                    <ContentCopy />
                  </IconButton>
                </InputAdornment>,
                endAdornment: <InputAdornment position="end">
                  <IconButton aria-label="" onClick={e => setI(i + 1)}>
                    <CachedRounded />
                  </IconButton>
                </InputAdornment>,
              }}
            />
            <Typography variant='subtitle2'>
              The text above is mutable, use the refresh button to generate a new password or tap the text to edit/enter your own password.
            </Typography>
          </Stack>
          <Stack spacing={2} textAlign={'left'} mt={4}>
            <FormControl>
              <TextField inputProps={{ min: 10, max: 200 }} type={'number'} defaultValue={form.length} label='Length' onChange={e => {
                try {
                  const value = Math.floor(Number(e.target.value));
                  if (value >= 10) {
                    setForm({ ...form, length: value });
                  }
                } catch (error) { }
              }} />
              <FormHelperText>(Password will scroll horizontally if longer than container)</FormHelperText>
            </FormControl>
            <Typography>Settings</Typography>
            <FormGroup>
              <Stack direction={'row'}>
                <FormControlLabel {...!!form.symbols && { disabled }} control={<Checkbox onChange={onChange} defaultChecked={!!form.symbols} name='symbols' />} label="Symbols" />
                {!!form.symbols && <TextField fullWidth size='small' defaultValue={typeof form.symbols === 'boolean' ? '' : form.symbols} label="Override Symbols" onChange={e => {
                  setForm((form?: any) => ({ ...form, symbols: e.target.value }))
                }} />}
              </Stack>
              <FormControlLabel {...form.numbers && { disabled }} control={<Checkbox onChange={onChange} defaultChecked={form.numbers} name='numbers' />} label="Numbers" />
              <FormControlLabel {...form.uppercase && { disabled }} control={<Checkbox onChange={onChange} defaultChecked={form.uppercase} name='uppercase' />} label="Uppercase" />
              <FormControlLabel {...form.lowercase && { disabled }} control={<Checkbox onChange={onChange} defaultChecked={form.lowercase} name='lowercase' />} label="Lowercase" />
            </FormGroup>
            <FormControl>
              <TextField type={'text'} defaultValue={form.exclude} label="Exclude" />
              <FormHelperText>(The textfield value will be used to exclude characters from the password.)</FormHelperText>
            </FormControl>
          </Stack>
        </Box>
      </Container>

    </ThemeProvider >
  );
}

export default App;

const Icon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48"
    viewBox="0 0 24 24" fill="none" strokeWidth="2"
    strokeLinecap="round" strokeLinejoin="round">
    <path pathLength="1" className="moon-icon" d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
    <circle pathLength="1" className="sun-icon" cx="12" cy="12" r="5"></circle>
    <line pathLength="1" className="sun-icon" x1="12" y1="1" x2="12" y2="3"></line>
    <line pathLength="1" className="sun-icon" x1="12" y1="21" x2="12" y2="23"></line>
    <line pathLength="1" className="sun-icon" x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
    <line pathLength="1" className="sun-icon" x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
    <line pathLength="1" className="sun-icon" x1="1" y1="12" x2="3" y2="12"></line>
    <line pathLength="1" className="sun-icon" x1="21" y1="12" x2="23" y2="12"></line>
    <line pathLength="1" className="sun-icon" x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
    <line pathLength="1" className="sun-icon" x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
  </svg>
}